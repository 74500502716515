@mixin mobile {
  @media (max-width: 900px) { @content; }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  user-select: none;
}

$main: #ECECEC;
$bg: #101010;

html {
  @include mobile {
    overflow-x: hidden;
    max-width: 100vw;
  }
}

body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  background: #0E0E0E;
  overflow: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  @include mobile {
    height: auto;
    overflow-y: visible;
    overflow-x: hidden;
  }

  main {
    display: block;
    width: 100vw;
    height: auto;
    overflow-x:hidden;
  }

  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;

    .wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .logo {
      margin-top: 20px;
      width: 7vw;

      @include mobile {
        width: 40vw;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  footer {
    position: absolute;
    top: 15px;
    right: 15px;
    transform-origin: center;
    font-size: 14px;
    font-family: 'Monument', sans-serif, Helvetica;
    text-transform: uppercase;
    color: $main;
    z-index: 12;

    @include mobile {
      display: none;
    }

    a {
      padding: 5px;
      margin-left: 15px;
      text-decoration: none;
      color: inherit;
    }
  }

  .preloader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 600;
    pointer-events: none;
  }

  .videos {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100%;

    .shop-mobile {
      margin-top: 15vw;
      font-family: 'Monument';
      color: $main;
      font-size: 28vw;
      width: 100%;
      overflow: hidden;
      text-align: center;
      display: none;

      @include mobile {
        display: block;
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    @include mobile {
      padding-bottom: 10vw;
      height: auto;
    }
  }

  .menu {
    // display: none;
    position: absolute;
    bottom: calc(20px);
    left: 20px;
    z-index: 20;
    font-family: 'Monument' , sans-serif, Helvetica;
    color: $main;
    user-select: none;

    @include mobile {
      display: none;
    }

    &:hover {
      li {
        opacity: 0.4 !important
      }
    }

    .wrapper {
      overflow: hidden;
      min-height: 5vw
    }

    ul li {
      position: relative;
      display: inline-block;
      font-size: 5vw;
      line-height: 5vw;
      list-style: none;
      text-transform: uppercase;
      transition: opacity 0.1s;
      // text-shadow: $main 0px 0 10px;;

      &:hover {
        cursor: pointer;
        opacity: 1 !important;
      }

      span {
        font-family: 'Snell';
        text-transform: none;
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        font-size: 1.5vw;
        line-height: 1.5vw;
        margin-top: 0.45vw;
        transform: translateX(110%);
      }
    }
  }

  .vitaly1 {
    @include mobile {
      margin-left: 5vw;
      margin-top: 25vw;
    }
  }

  .vitaly2 {
    @include mobile {
      margin-left: 5vw;
      margin-top: 15vw;
    }
  }

  .vitaly3 {
    @include mobile {
      margin-left: 5vw;
      margin-top: 15vw;
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  .vitaly4 {
    @include mobile {
      margin-left: 5vw;
      margin-top: 15vw;
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  .vitaly5 {
    @include mobile {
      margin-left: 5vw;
      margin-top: 15vw;
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  .video {
    bottom: 20px;
    right: 20px;
    position: absolute;
    z-index: 26;
    width: 21vw;
    height: 21vw;
    display: none;

    @include mobile {
      bottom: 0;
      right: 0;
      position: relative;
      width: 90vw;
      height: 90vw;
      display: block;
      opacity: 0;
    }

    &-content {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
    }

    &-thumbnail {
      position: absolute;
      width: 100%;
      height: 100%;
      transform-origin: center;
      transform: scale(1.1);
      opacity: 1;
      transition: opacity 0.4s;
      z-index: 10;
      display: none;

      @include mobile {
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-player {
      position: absolute;
      width: 100%;
      height: 100%;

      video {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
      font-size: 11px;
      font-family: 'Monument';
      text-transform: uppercase;
      color: $main;
      opacity: 0;
      transition: opacity 0.4s;

      display: none;

      @include mobile {
        display: flex;
        opacity: 1;
      }

      span {
        display: block;
      }
    }
  }

  .players {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;  
  }

  .player {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    z-index: 950;

    &-wrapper {
      position: relative;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 960;
    }

    &-background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      z-index: 11;
    }

    .wrapper {
      width: 40vw;

      @include mobile {
        width: 90vw;
      }
    }

    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 5px;
      font-size: 52px;
      font-family: 'Monument';
      color: $main;
      text-transform: uppercase;
      overflow: hidden;

      @include mobile {
        right: 50%;
        transform: translateX(calc(50% + 5px));
      }

      span {
        display: block;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &-video {
      width: 40vw;
      height: 40vw;
      position: relative;
      z-index: 998;
      overflow: hidden;

      @include mobile {
        width: 90vw;
        height: 90vw;
      }

      .tap {
        position: absolute;
        z-index: 18;
        width: 100%;
        text-align: center;
        font-family: 'Monument';
        color: #E5E5E5;
        font-size: 24px;
        margin-top: 45vw;
        display: none;
        z-index: 999;

        @include mobile {
          display: block;
        }
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-top: 10px;
      font-size: 12px;
      font-family: 'Monument';
      text-transform: uppercase;
      color: $main;
      overflow: hidden;

      span {
        display: block;
      }
    }
  }
}
